import { ThemeProvider }        from 'styled-components';
import { BrowserRouter, Route } from 'react-router-dom';
import GlobalStyles             from './components/GlobalStyles';
import { Survey }               from './modules/Survey';
import arhausTheme              from './theme';

export const App = () => (
  <ThemeProvider theme={arhausTheme}>
    <GlobalStyles />

    <BrowserRouter>
      <Route exact path='/' component={Survey} />
      <Route exact path='/:groupId/:surveyId' component={Survey} />
    </BrowserRouter>
  </ThemeProvider>
);
