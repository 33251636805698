const arhausTheme = {
  color: {
    grayscale: {
      1000: '#000',
      950: '#1d1d1d',
      900: '#222',
      800: '#262c30',
      700: '#4f4f4f',
      600: '#707070',
      550: '#707070',
      500: '#bdbdbd',
      400: '#d2d7d5',
      300: '#edece8',
      200: '#f8f8f8',
      100: '#fff',
    },
    primary: {
      1000: '#4b5f59',
      500: '#1e372f',
      300: '#8f9b97',
      200: '#bbc2c0',
      100: '#d2d7d5',
    },
    utility: {
      error: '#89331c',
      success: '#4f4f4f',
    },
  },
  type: {
    face: {
      primary: '"NeueHaasUnica", sans-serif',
      serif: '"Times New Roman", "Times", "Baskerville", "Georgia", serif',
    },
    baseSize: '100%',
    baseLineHeight: 1.5,
    weight: {
      bold: 700,
      regular: 400,
      light: 300,
      extralight: 200,
    },
  },
  size: {
    formElementHeight: '40px',
    formElementPadding: '12px 15px',
    baseMinWidth: '320px',
  },
  breakpoint: {
    phoneSmall: '321px',
    phone: '376px',
    phoneLarge: '415px',
    tablet: '769px',
    desktop: '1025px',
    widescreen: '1201px',
    laptop: '1401px',
  },
  animation: {
    speed: '0.3s',
  },
};

export default arhausTheme;
