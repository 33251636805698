import styled                  from 'styled-components';
import { Formik, Form, Field } from 'formik';
import { Question }            from '../../../components/Question';
import { RadioInput }          from '../../../components/RadioInput';
import { TextInput }           from '../../../components/TextInput';
import { Button }              from '../../../components/Button';
import { FormikOnError }       from '../../../components/FormikOnError';

export const SurveyPage = ({ 
  data: {
    questions,
    success
  }, 
  onSubmit, 
  isSuccessResponse, 
  validationSchema,
  setValidateStatus,
  validateOnChange
}) => (
  <>
    {isSuccessResponse ? (
      <>
        <SurveyPage.Title>
          Thank you for sharing your feedback.
        </SurveyPage.Title>

        <p>
          If you have any questions or concerns about your recent furniture purchase, please contact our Concierge team at <u>concierge@arhaus.com</u>, or call 866-427-4287.
        </p>
      </>
    ) : (
      <>
        <SurveyPage.Title>Thank you for choosing Arhaus!</SurveyPage.Title>
        {success ?
        <p>
         We have received your survey response. Thank you for taking the time to share more about your experience with Arhaus.
         If at any time you wish to contact us, please email <u>concierge@arhaus.com</u> or call 866-427-4287 and a Concierge representative will assist you.
        </p>
         :  <p>
         Please take a moment to share feedback on your recent Arhaus purchase so that we may continue to improve in any way possible
         For the following brief questions, please rate us using a scale of 1-5, with 5 being the highest possible rating. 
         If at any time you wish to contact us, please email <u>concierge@arhaus.com</u> or call 866-427-4287 and a Concierge representative will assist you.
       </p>}
        <Formik
          initialValues    = {{}}
          validationSchema = {validationSchema}
          onSubmit         = {onSubmit}
          validateOnChange = {validateOnChange}
          validateOnBlur   = {false}
        >
          {({ errors }) => (
            <SurveyPage.Form>
              <FormikOnError setValidateStatus={setValidateStatus}>
                {(questions ?? []).map(question => (
                  <Question 
                    key            = {question.id}
                    questionNumber = {question.number}
                    title          = {question.question}
                    required       = {question.isRequired}
                    error          = {errors[question.id]}
                  >
                    <>
                      {(question?.answers ?? []).map(answer => (
                        <Field 
                          key       = {answer.id}
                          name      = {`${question.number}.answerId`}
                          value     = {`${answer.id}`}
                          component = {RadioInput}
                          label     = {answer.answer}
                        />
                      ))}

                      <p>Comments</p>
                      <Field name={`${question.number}.comment`} id="satisfied.comment" component={TextInput} />
                    </>
                  </Question>
                ))}
              </FormikOnError>

              {(!success &&   <SurveyPage.ButtonContainer>
                <Button>Submit Survey</Button>
              </SurveyPage.ButtonContainer>)}
            </SurveyPage.Form >
          )}
          
        </Formik>
      </>
    )}
  </>
);

SurveyPage.Title = styled.p`
  text-align: center;
`;


SurveyPage.ButtonContainer = styled.div`
  && {
    display         : flex;
    margin-top      : 17px;
    margin-bottom   : 5px;
    flex-flow       : row nowrap;
    justify-content : center;
    margin-bottom   : 100px;
    width           : 330px;

    @media screen and (min-width: 376px) {
      width: 575px;
    }
  }
`;

SurveyPage.Form = styled(Form)`
  && {
    display        : flex;
    flex-direction : column;
    align-items    : flex-start;
  }
`;
