import { useEffect } from 'react';
import isObject from 'lodash/isObject';
import { useFormikContext } from 'formik';

const getFirstErrorKey = (object, keys = []) => {
  const firstErrorKey = Object.keys(object)[0];
  if (isObject(object[firstErrorKey])) {
    return getFirstErrorKey(object[firstErrorKey], [...keys, firstErrorKey]);
  }
  return [...keys, firstErrorKey].join('.');
};

export const FormikOnError = ({ children, setValidateStatus }) => {
  const formik = useFormikContext();

  useEffect(() => {
    if (!formik.isValid && formik.isSubmitting && formik.submitCount > 0) {
      const firstErrorKey = getFirstErrorKey(formik.errors);
      if (global.window.document.getElementsByName(firstErrorKey).length) {
        setValidateStatus(true);
        global.window.document.getElementsByName(firstErrorKey)[0].scrollIntoView({ block: 'center' });
      }
    }
  }, [formik.submitCount, formik.isValid, formik.errors, formik.isSubmitting, setValidateStatus]);
  return children;
};
