import styled from 'styled-components';

export const Button = styled.button(({ theme, variant }) => {
  const { breakpoint, color, size, type } = theme;

  let variantStyles;

  switch (variant) {
    case 'secondary':
      variantStyles = `
        color        : ${color.primary[500]};
        background   : ${color.grayscale[100]};
        border-color : ${color.primary[500]};

        &:hover {
          color        : ${color.primary[500]};
          background   : ${color.grayscale[200]};
          border-color : ${color.primary[500]};
        }

        &:focus {
          color        : ${color.primary[500]};
          background   : ${color.primary[200]};
          border-color : ${color.primary[500]};
        }

        &:disabled {
          color        : ${color.grayscale[500]};
          background   : ${color.grayscale[200]};
          border-color : ${color.grayscale[200]};
        }
      `;
      break;
    default:
      variantStyles = '';
  }

  return `
    -webkit-appearance    : none;
    -webkit-border-radius : 0;
    background            : ${color.primary[500]};
    border                : 1px solid ${color.primary[500]};
    border-radius         : 0;
    box-sizing            : border-box;
    color                 : ${color.grayscale[100]};
    cursor                : pointer;
    display               : inline-block;
    font-family           : ${type.face.primary};
    font-size             : 0.6875rem;
    font-weight           : bold;
    letter-spacing        : 0.15em;
    line-height           : 1.1;
    min-height            : ${size.formElementHeight};
    padding               : 10px 20px;
    text-align            : center;
    text-transform        : uppercase;
    vertical-align        : top;

    &:hover {
      color        : ${color.grayscale[100]};
      background   : ${color.primary[1000]};
      border-color : ${color.primary[1000]};
    }

    &:focus {
      color        : ${color.grayscale[100]};
      background   : ${color.primary[1000]};
      border-color : ${color.primary[1000]};
    }

    &:disabled {
      color          : ${color.grayscale[500]};
      background     : ${color.grayscale[200]};
      border-color   : ${color.grayscale[200]};
      pointer-events : none;
      cursor         : default;
      outline        : none !important;
    }

    @media screen and (min-width: ${breakpoint.phone}) {
      min-width: 182px;
    }

    ${variantStyles}
  `;
});
