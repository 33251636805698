import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_APIBASEURL;

export const callApi = (url, method = 'GET', data = undefined, config = undefined) => {
  const axiosConfig = {
    url,
    method,
    ...config,
  };

  if (data) {
    if (method.toUpperCase() === 'GET') {
      axiosConfig.params = {
        ...config?.params,
        ...data
      };
    } else {
      axiosConfig.data = data;
    }
  }

  const request = axios(axiosConfig);

  return request;
};
