import styled from 'styled-components';
import { Logo } from '../Logo';

export const Header = () => (
  <Header.Wrapper>
    <a href="https://www.arhaus.com/">
      <Logo />
    </a>
  </Header.Wrapper>
);

Header.Wrapper = styled.header`
  display         : flex;
  flex-flow       : column nowrap;
  justify-content : center;
  align-items     : center;
  padding-top     : 30px;
`;