import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle(({ theme }) => {
  const { animation, breakpoint, color, size, type } = theme;

  return /*css*/ `
    /**
     * @license
     * MyFonts Webfont Build ID 4152634, 2021-08-20T11:35:44-0400
     * 
     * The fonts listed in this notice are subject to the End User License
     * Agreement(s) entered into by the website owner. All other parties are 
     * explicitly restricted from using the Licensed Webfonts(s).
     * 
     * You may obtain a valid license at the URLs below.
     * 
     * Webfont: NeueHaasUnicaW1G-XBlack by Linotype
     * URL: https://www.myfonts.com/fonts/linotype/neue-haas-unica/extra-black/
     * Copyright: Copyright &amp;#x00A9; 2015 Monotype Imaging Inc.  All rights reserved.
     * 
     * Webfont: NeueHaasUnicaW1G-Black by Linotype
     * URL: https://www.myfonts.com/fonts/linotype/neue-haas-unica/black/
     * Copyright: Copyright &amp;#x00A9; 2016 Monotype Imaging Inc. All rights reserved.
     * 
     * Webfont: NeueHaasUnicaW1G-BlackIt by Linotype
     * URL: https://www.myfonts.com/fonts/linotype/neue-haas-unica/black-italic/
     * Copyright: Copyright &amp;#x00A9; 2016 Monotype Imaging Inc. All rights reserved.
     * 
     * Webfont: NeueHaasUnicaW1G-Bold by Linotype
     * URL: https://www.myfonts.com/fonts/linotype/neue-haas-unica/bold/
     * Copyright: Copyright &amp;#x00A9; 2016 Monotype Imaging Inc. All rights reserved.
     * 
     * Webfont: NeueHaasUnicaW1G-BoldItalic by Linotype
     * URL: https://www.myfonts.com/fonts/linotype/neue-haas-unica/bold-italic/
     * Copyright: Copyright &amp;#x00A9; 2016 Monotype Imaging Inc. All rights reserved.
     * 
     * Webfont: NeueHaasUnicaW1G-XBlackIt by Linotype
     * URL: https://www.myfonts.com/fonts/linotype/neue-haas-unica/extra-black-italic/
     * Copyright: Copyright &amp;#x00A9; 2016 Monotype Imaging Inc. All rights reserved.
     * 
     * Webfont: NeueHaasUnicaW1G-HeavyIt by Linotype
     * URL: https://www.myfonts.com/fonts/linotype/neue-haas-unica/heavy-italic/
     * Copyright: Copyright &amp;#x00A9; 2016 Monotype Imaging Inc. All rights reserved.
     * 
     * Webfont: NeueHaasUnicaW1G-Heavy by Linotype
     * URL: https://www.myfonts.com/fonts/linotype/neue-haas-unica/heavy/
     * Copyright: Copyright &amp;#x00A9; 2016 Monotype Imaging Inc. All rights reserved.
     * 
     * Webfont: NeueHaasUnicaW1G-Italic by Linotype
     * URL: https://www.myfonts.com/fonts/linotype/neue-haas-unica/italic/
     * Copyright: Copyright &amp;#x00A9; 2016 Monotype Imaging Inc. All rights reserved.
     * 
     * Webfont: NeueHaasUnicaW1G-LightIt by Linotype
     * URL: https://www.myfonts.com/fonts/linotype/neue-haas-unica/light-italic/
     * Copyright: Copyright &amp;#x00A9; 2016 Monotype Imaging Inc. All rights reserved.
     * 
     * Webfont: NeueHaasUnicaW1G-Medium by Linotype
     * URL: https://www.myfonts.com/fonts/linotype/neue-haas-unica/medium/
     * Copyright: Copyright &amp;#x00A9; 2016 Monotype Imaging Inc. All rights reserved.
     * 
     * Webfont: NeueHaasUnicaW1G-MediumIt by Linotype
     * URL: https://www.myfonts.com/fonts/linotype/neue-haas-unica/medium-italic/
     * Copyright: Copyright &amp;#x00A9; 2016 Monotype Imaging Inc. All rights reserved.
     * 
     * Webfont: NeueHaasUnicaW1G-Light by Linotype
     * URL: https://www.myfonts.com/fonts/linotype/neue-haas-unica/light/
     * Copyright: Copyright &amp;#x00A9; 2016 Monotype Imaging Inc. All rights reserved.
     * 
     * Webfont: NeueHaasUnicaW1G-Regular by Linotype
     * URL: https://www.myfonts.com/fonts/linotype/neue-haas-unica/regular/
     * Copyright: Copyright &amp;#x00A9; 2016 Monotype Imaging Inc. All rights reserved.
     * 
     * Webfont: NeueHaasUnicaW1G-UltLightIt by Linotype
     * URL: https://www.myfonts.com/fonts/linotype/neue-haas-unica/ultra-light-italic/
     * Copyright: Copyright &amp;#x00A9; 2016 Monotype Imaging Inc. All rights reserved.
     * 
     * Webfont: NeueHaasUnicaW1G-ThinItalic by Linotype
     * URL: https://www.myfonts.com/fonts/linotype/neue-haas-unica/thin-italic/
     * Copyright: Copyright &amp;#x00A9; 2016 Monotype Imaging Inc. All rights reserved.
     * 
     * Webfont: NeueHaasUnicaW1G-Thin by Linotype
     * URL: https://www.myfonts.com/fonts/linotype/neue-haas-unica/thin/
     * Copyright: Copyright &amp;#x00A9; 2016 Monotype Imaging Inc. All rights reserved.
     * 
     * Webfont: NeueHaasUnicaW1G-UltraLight by Linotype
     * URL: https://www.myfonts.com/fonts/linotype/neue-haas-unica/ultra-light/
     * Copyright: Copyright &amp;#x00A9; 2016 Monotype Imaging Inc. All rights reserved.
     * 
     * 
     * 
     * © 2021 MyFonts Inc
    */

    /* @import must be at top of file, otherwise CSS will not work */
    @import url("//hello.myfonts.net/count/3f5d3a");

    /* Ultra Light (NeueHaasUnicaW1G-UltraLight/NeueHaasUnicaW1G-UltraLightIt)*/
    @font-face {
      font-family: "NeueHaasUnica";
      src: url('webFonts/NeueHaasUnicaW1GUltraLight/font.woff2') format('woff2'), url('webFonts/NeueHaasUnicaW1GUltraLight/font.woff') format('woff');
      font-weight: 100;
      font-style: normal;
    }
    @font-face {
      font-family: "NeueHaasUnica";
      src: url('webFonts/NeueHaasUnicaW1GUltLightIt/font.woff2') format('woff2'), url('webFonts/NeueHaasUnicaW1GUltLightIt/font.woff') format('woff');
      font-weight: 100;
      font-style: italic;
    }

    /* Thin (NeueHaasUnicaW1G-Thin/NeueHaasUnicaW1G-ThinItalic) */
    @font-face {
      font-family: "NeueHaasUnica";
      src: url('webFonts/NeueHaasUnicaW1GThin/font.woff2') format('woff2'), url('webFonts/NeueHaasUnicaW1GThin/font.woff') format('woff');
      font-weight: 200;
      font-style: normal;
    }
    @font-face {
      font-family: "NeueHaasUnica";
      src: url('webFonts/NeueHaasUnicaW1GThinItalic/font.woff2') format('woff2'), url('webFonts/NeueHaasUnicaW1GThinItalic/font.woff') format('woff');
      font-weight: 200;
      font-style: italic;
    }

    /* Light (NeueHaasUnicaW1G-Light/NeueHaasUnicaW1G-LightIt) */
    @font-face {
      font-family: "NeueHaasUnica";
      src: url('webFonts/NeueHaasUnicaW1GLight/font.woff2') format('woff2'), url('webFonts/NeueHaasUnicaW1GLight/font.woff') format('woff');
      font-weight: 300;
      font-style: normal;
    }
    @font-face {
      font-family: "NeueHaasUnica";
      src: url('webFonts/NeueHaasUnicaW1GLightIt/font.woff2') format('woff2'), url('webFonts/NeueHaasUnicaW1GLightIt/font.woff') format('woff');
      font-weight: 300;
      font-style: italic;
    }

    /* Regular (NeueHaasUnicaW1G-Regular/NeueHaasUnicaW1G-Italic) */
    @font-face {
      font-family: "NeueHaasUnica";
      src: url('webFonts/NeueHaasUnicaW1GRegular/font.woff2') format('woff2'), url('webFonts/NeueHaasUnicaW1GRegular/font.woff') format('woff');
      font-weight: 400;
      font-style: normal;
    }
    @font-face {
      font-family: "NeueHaasUnica";
      src: url('webFonts/NeueHaasUnicaW1GItalic/font.woff2') format('woff2'), url('webFonts/NeueHaasUnicaW1GItalic/font.woff') format('woff');
      font-weight: 400;
      font-style: italic;
    }

    /* Medium (NeueHaasUnicaW1G-Medium/NeueHaasUnicaW1G-MediumIt)  */
    @font-face {
      font-family: "NeueHaasUnica";
      src: url('webFonts/NeueHaasUnicaW1GMedium/font.woff2') format('woff2'), url('webFonts/NeueHaasUnicaW1GMedium/font.woff') format('woff');
      font-weight: 500;
      font-style: normal;
    }
    @font-face {
      font-family: "NeueHaasUnica";
      src: url('webFonts/NeueHaasUnicaW1GMediumIt/font.woff2') format('woff2'), url('webFonts/NeueHaasUnicaW1GMediumIt/font.woff') format('woff');
      font-weight: 500;
      font-style: italic;
    }

    /* Bold (NeueHaasUnicaW1G-Bold/NeueHaasUnicaW1G-BoldItalic) */
    @font-face {
      font-family: "NeueHaasUnica";
      src: url('webFonts/NeueHaasUnicaW1GBold/font.woff2') format('woff2'), url('webFonts/NeueHaasUnicaW1GBold/font.woff') format('woff');
      font-weight: 600;
      font-style: normal;
    }
    @font-face {
      font-family: "NeueHaasUnica";
      src: url('webFonts/NeueHaasUnicaW1GBoldItalic/font.woff2') format('woff2'), url('webFonts/NeueHaasUnicaW1GBoldItalic/font.woff') format('woff');
      font-weight: 600;
      font-style: italic;
    }

    /* Heavy (NeueHaasUnicaW1G-Heavy/NeueHaasUnicaW1G-HeavyIt) */
    @font-face {
      font-family: "NeueHaasUnica";
      src: url('webFonts/NeueHaasUnicaW1GHeavy/font.woff2') format('woff2'), url('webFonts/NeueHaasUnicaW1GHeavy/font.woff') format('woff');
      font-weight: 700;
      font-style: normal;
    }
    @font-face {
      font-family: "NeueHaasUnica";
      src: url('webFonts/NeueHaasUnicaW1GHeavyIt/font.woff2') format('woff2'), url('webFonts/NeueHaasUnicaW1GHeavyIt/font.woff') format('woff');
      font-weight: 700;
      font-style: italic;
    }

    /* Black (NeueHaasUnicaW1G-Black/NeueHaasUnicaW1G-BlackIt) */
    @font-face {
      font-family: "NeueHaasUnica";
      src: url('webFonts/NeueHaasUnicaW1GBlack/font.woff2') format('woff2'), url('webFonts/NeueHaasUnicaW1GBlack/font.woff') format('woff');
      font-weight: 800;
      font-style: normal;
    }
    @font-face {
      font-family: "NeueHaasUnica";
      src: url('webFonts/NeueHaasUnicaW1GBlackIt/font.woff2') format('woff2'), url('webFonts/NeueHaasUnicaW1GBlackIt/font.woff') format('woff');
      font-weight: 800;
      font-style: italic;
    }

    /* XBlack (NeueHaasUnicaW1G-XBlack/NeueHaasUnicaW1G-XBlackIt) */
    @font-face {
      font-family: "NeueHaasUnica";
      src: url('webFonts/NeueHaasUnicaW1GXBlack/font.woff2') format('woff2'), url('webFonts/NeueHaasUnicaW1GXBlack/font.woff') format('woff');
      font-weight: 900;
      font-style: normal;
    }
    @font-face {
      font-family: "NeueHaasUnica";
      src: url('webFonts/NeueHaasUnicaW1GXBlackIt/font.woff2') format('woff2'), url('webFonts/NeueHaasUnicaW1GXBlackIt/font.woff') format('woff');
      font-weight: 900;
      font-style: italic;
    }

    main {
      display: block;
    }
    
    hr {
      box-sizing: content-box;
      height: 0;
      overflow: visible;
    }

    pre {
      font-family: monospace, monospace;
      font-size: 1em;
    }
    
    abbr[title] {
      border-bottom: none;
      text-decoration: underline;
      text-decoration: underline dotted;
    }

    strong {
      font-weight: bolder;
    }

    kbd,
    samp {
      font-family: monospace, monospace;
      font-size: 1em;
    }

    small {
      font-size: 80%;
    }

    sub,
    sup {
      font-size: 75%;
      line-height: 0;
      position: relative;
      vertical-align: baseline;
    }
    
    sub {
      bottom: -0.25em;
    }
    
    sup {
      top: -0.5em;
    }

    button,
    input,
    optgroup,
    select,
    textarea {
      font-family: inherit;
      font-size: 100%;
      line-height: 1.15;
      margin: 0;
    }

    button,
    input {
      overflow: visible;
    }

    button,
    select {
      text-transform: none;
    }

    button,
    [type="button"],
    [type="reset"],
    [type="submit"] {
      -webkit-appearance: button;
    }

    button::-moz-focus-inner,
    [type="button"]::-moz-focus-inner,
    [type="reset"]::-moz-focus-inner,
    [type="submit"]::-moz-focus-inner {
      border-style: none;
      padding: 0;
    }

    button:-moz-focusring,
    [type="button"]:-moz-focusring,
    [type="reset"]:-moz-focusring,
    [type="submit"]:-moz-focusring {
      outline: 1px dotted ButtonText;
    }

    fieldset {
      padding: 0.35em 0.75em 0.625em;
    }

    legend {
      box-sizing: border-box;
      color: inherit;
      display: table;
      max-width: 100%;
      padding: 0;
      white-space: normal;
    }

    progress {
      vertical-align: baseline;
    }

    textarea {
      overflow: auto;
    }

    [type="checkbox"],
    [type="radio"] {
      box-sizing: border-box;
      padding: 0;
    }

    [type="number"]::-webkit-inner-spin-button,
    [type="number"]::-webkit-outer-spin-button {
      height: auto;
    }
 
    [type="search"] {
      -webkit-appearance: textfield;
      outline-offset: -2px;
    }

    [type="search"]::-webkit-search-decoration {
      -webkit-appearance: none;
    }

    ::-webkit-file-upload-button {
      -webkit-appearance: button;
      font: inherit;
    }

    details {
      display: block;
    }

    summary {
      display: list-item;
    }

    template {
      display: none;
    }

    [hidden] {
      display: none;
    }
  
    html {
      line-height: 1.15;
      -webkit-text-size-adjust: 100%;
      box-sizing: border-box;
      
      @media screen and (min-width: ${breakpoint.widescreen}) {
        overflow: visible !important;
      }
    }
    
    *,
    *::before,
    *::after {
      box-sizing: inherit;
    }
    
    body {
      margin: 0;
      min-width: ${size.baseMinWidth};
      color: ${color.grayscale[950]};
      font-family: ${type.face.primary};
      font-weight: 300;
      font-style: normal;
      font-size: ${type.baseSize};
      line-height: ${type.baseLineHeight};
      background: ${color.grayscale[100]};
      -moz-osx-font-smoothing: grayscale;

      @media screen and (min-width: ${breakpoint.widescreen}) {
        overflow: visible !important;
      }
    }
    
    body,
    input,
    textarea,
    button,
    select {
      -webkit-font-smoothing: antialiased;
      text-size-adjust: 100%;
    }
    
    img {
      border-style: none;
      max-width: 100%;
      height: auto;
    }
    
    button,
    a,
    input[type="submit"],
    input[type="reset"] {
      @media screen and (min-width: ${breakpoint.desktop}) {
        transition: color ${animation.speed} ease,
                    background ${animation.speed} ease,
                    border-color ${animation.speed} ease,
                    box-shadow ${animation.speed} ease,
                    opacity ${animation.speed} ease;
      }
    }

    svg {
      transition: stroke ${animation.speed} ease, 
                  fill ${animation.speed} ease;
    }

    form,
    fieldset {
      margin: 0;
      padding: 0;
      border-style: none;
    }

    h1,
    h2,
    h3 {
      margin: 0 0 20px;
      font-family: ${type.face.primary};
      font-weight: 200;
      font-style: normal;
      line-height: 1.333;
      letter-spacing: 0.1em;
      color: inherit;
      text-transform: uppercase;
      
      @media screen and (min-width: ${breakpoint.desktop}) {
        margin: 0 0 30px;
        line-height: 1.4545;
      }

      a {
        color: inherit;
        text-decoration: none;
        
        &:hover {
          opacity: 0.6;
        }
      }
    }

    h4,
    h5,
    h6 {
      margin: 0 0 20px;
      font-family: ${type.face.primary};
      font-weight: 300;
      font-style: normal;
      line-height: 1.333;
      letter-spacing: 0.15em;
      color: inherit;
      text-transform: uppercase;
      
      @media screen and (min-width: ${breakpoint.desktop}) {
        margin: 0 0 30px;
        line-height: 1.4545;
      }

      a {
        color: inherit;
        text-decoration: none;
        
        &:hover {
          opacity: 0.6;
        }
      }
    }

    h1 {
      font-size: 2.25rem;
      line-height: 1.11;
      letter-spacing: 0.1em;
      
      @media screen and (min-width: ${breakpoint.desktop}) {
        font-size: 3rem;
        line-height: 1;
      }
    }

    h2 {
      font-size: 2rem;
      line-height: 1.125;
      
      @media screen and (min-width: ${breakpoint.desktop}) {
        font-size: 2.5rem;
        line-height: 1.2;
      }
    }

    h3 {
      font-size: 1.5;
      line-height: 1.1667;
      
      @media screen and (min-width: ${breakpoint.desktop}) {
        font-size: 2rem;
        line-height: 1.25;
      }
    }

    h4 {
      font-size: 1.125rem;
      
      @media screen and (min-width: ${breakpoint.desktop}) {
        font-size: 1.375rem;
      }
    }

    h5 {
      font-size: 1.375rem;
      line-height: 1.27;
      letter-spacing: 0;
      text-transform: none;
    }

    h6 {
      font-size: 1.125rem;
      line-height: 1.22;
      letter-spacing: 0;
      text-transform: none;
    }

    p {
      margin: 0 0 15px;
    }

    b,
    strong {
      font-weight: 700;
    }

    a {
      background-color: transparent;
      color: ${color.grayscale[950]};
      text-decoration: underline;
      
      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  `;
});

export default GlobalStyles;
