import styled from 'styled-components';

export const Question = ({ title, questionNumber, required, children, error }) => (
  <Question.Wrapper>
    <Question.Title>Question {questionNumber}: {title} {required ? <span>*</span> : ''} </Question.Title>
    {error && <Question.Error>{error.answerId}</Question.Error>}
    <Question.Container>{children}</Question.Container>
  </Question.Wrapper>
);

Question.Wrapper = styled.div`
  margin-top    : 50px;
  margin-bottom : 30px;
`;

Question.Title = styled.p`
  border-bottom  : 2px solid black;
  padding-bottom : 5px;
  margin-bottom  : 20px;
  width          : fit-content;
  font-size      : 1rem;

  span {
    color: red;
  }
`;

Question.Container = styled.div`
  display        : flex;
  flex-direction : column;
  max-width      : 575px;

  p {
    margin-top    : 20px;
    margin-bottom : 10px;
  }
`;

Question.Error = styled.p`
  color         : red;
  margin-bottom : 20px;
`;