import styled from 'styled-components';

export const TextInput = ({ field }) => (
  <TextInput.Wrapper>
    <TextInput.StyledInput {...field} multiple />
  </TextInput.Wrapper>
);

TextInput.StyledInput = styled.textarea`
  -webkit-appearance    : none;
  -webkit-border-radius : 0;
  box-sizing            : border-box;
  display               : block;
  width                 : 100%;
  height                : 40px;
  padding               : 12px 15px;
  font-size             : 0.75rem;
  letter-spacing        : 0.5px;
  border                : 1px solid #edece8;
  max-width             : 330px;
  min-width             : 330px;
  max-height            : 150px;
  min-height            : 150px;

  &:focus {
    border-color: #222;
  }

  &:-webkit-autofill {
    filter             : none;
    -webkit-box-shadow : 0 0 0 1000px #fff inset !important;
    box-shadow         : 0 0 0 1000px #fff inset !important;
  }

  @media screen and (min-width: 376px) {
    font-size: 0.875rem;
    max-width: 575px;
    min-width: 575px;
  }
`;

TextInput.Error = styled.p`
  margin-top : 6px;
  color      : red;
  font-size  : 12px;
  position   : absolute;
`;

TextInput.Wrapper = styled.div`
  position: relative;
`;