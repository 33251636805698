import styled from 'styled-components';

export const RadioInput = ({ field, label, form: { errors, setFieldValue }, value }) => (
  <RadioInput.Wrapper>
    <RadioInput.StyledInput type="radio" {...field} onChange={() => setFieldValue(field.name, value)} />
    <RadioInput.Label hasError={errors[field.name]}>{label}</RadioInput.Label>
  </RadioInput.Wrapper>
);

RadioInput.Wrapper = styled.div`
  display: flex;
`;

RadioInput.StyledInput = styled.input`
  margin-right  : 10px;
  margin-top    : 3px;
  cursor        : pointer;
  transform     : scale(2);
  margin-left   : 5px;
  margin-right  : 20px;
  margin-bottom : 20px;

  &:checked:after{
    background-color:green;
  }
`;

RadioInput.Label = styled.label`
  text-align: justify;
  ${({ hasError }) => hasError && `color: red;`}
`;

RadioInput.Error = styled.p`
  margin-top : 6px;
  color      : red;
  font-size  : 12px;
`;