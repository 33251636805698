import { 
  useCallback, 
  useEffect, 
  useState, 
  useMemo 
}                     from 'react';
import * as Yup       from 'yup';
import { Layout }     from '../../components/Layout';
import { Header }     from '../../components/Header';
import { Loader }     from '../../components/Loader';
import { callApi }    from '../../shared/apiCaller';
import { SurveyPage } from './page/SurveyPage';

export const Survey = ({ match }) => {
  const { groupId, surveyId }                 = match.params;
  const [surveyData, setSurveyData]           = useState(null);
  const [validateStatus, setValidateStatus]   = useState(false);
  const [isSuccessResponse, setSuccessStatus] = useState(false);

  useEffect(
    () => {
      if (groupId && surveyId) {
        const fetchQuestions = async () => {
          const { data } = await callApi(`Survey/${groupId}/${surveyId}`);
          setSurveyData(data);
        }
  
        fetchQuestions();
      }
    },
    [groupId, surveyId]
  );

  const validationSchema = useMemo(
    () => {
      const requiredQuestions = (surveyData?.questions ?? []).filter(question => question.isRequired);
      const shemasValues = requiredQuestions.reduce((acc, cur) => {
        if (acc) {
          const formattedShemasValue = {
            [cur.number]: Yup.object().shape({
              answerId: Yup.string().required('Answer is required')
            })
          }

          return { ...acc, ...formattedShemasValue };
        }

        return acc;
      }, {});

      const schema = Yup.object().shape({
        ...shemasValues
      });

      return schema;
    },
    [surveyData]
  );

  const handleSubmit = useCallback(
    async (values) => {
      const answers = Object.keys(values).reduce((acc, cur) => {
        if (acc) {
          const formattedAnswer = {
            questionId : +cur,
            answerId   : +values[cur].answerId ?? '',
            Comment    : values[cur].comment ?? ''
          }

          return [...acc, formattedAnswer];
        }

        return [];
      }, []);

      const requestData = {
        surveyId      : surveyData.surveyId,
        surveyGroupId : surveyData.surveyGroupId,
        answers
      }

      const { data } = await callApi(`Survey/Post`, 'POST', requestData);
      
      if (data.success) {
        setSuccessStatus(true);
      }
     },
     [surveyData]
  );

  return (
    <>
      <Header />
        {surveyData ? (
          <Layout>
            <SurveyPage 
              data              = {surveyData}
              onSubmit          = {handleSubmit}
              isSuccessResponse = {isSuccessResponse}
              validationSchema  = {validationSchema}
              setValidateStatus = {setValidateStatus}
              validateStatus    = {validateStatus}
            />
          </Layout>
        ) : (
          <Loader />
        )}
    </>
  )
};
